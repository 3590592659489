/*################### Start - Normal View ###################*/
.CompanyCard__card-details-container {
  display: grid;
  grid-template-columns: 9fr 1fr;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #f1f1f1;

  width: 100%;
  min-height: 7rem;
  /* margin-bottom: 2rem; */

  /* position: relative; */
  text-transform: uppercase;
  font-size: var(--h2-font-size);
  font-weight: 600;
}

.CompanyCard__card-details-space {
  margin-bottom: 2rem;
}

.CompanyCard__card {
  /* border: 1px solid red; */

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  /* white-space: nowrap; */
}

.CompanyCard__focused {
  color: var(--primary-color);
  transform: translateY(-0.3rem);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  transition: 0.5s;
}

.CompanyCard__card-details-company {
  font-size: var(--h2-font-size);
  font-weight: 600;
}

.CompanyCard__card-details-vacsno {
  font-size: var(--tiny-font-size);
  color: var(--grey-color-2);
  text-align: center;
}

.CompanyCard__arrow {
  grid-row: span 2;
  transition: 0.4s;
}
.CompanyCard__disabled {
  color: var(--grey-color-2);
}

.CompanyCard__card-link {
  transition: 0.4s;
}
.CompanyCard__card-link:link,
.CompanyCard__card-link:visited {
  color: var(--grey-color-1);
  /* transition: all 0.4s; */

  cursor: pointer;
}

.CompanyCard__card-link_hover:hover {
  color: var(--primary-color);
  transform: translateY(-0.3rem);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
  transition: 0.5s;
}
.CompanyCard__card-link:active {
  transform: translateY(-0.1rem);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.CompanyCard__card-vacancies-container {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 0 auto 2rem auto;
}

.CompanyCard__card-vacancies-details-card {
  padding: 1rem;

  display: grid;
  grid-template-columns: 9fr 1fr;

  /* border: 1px solid black; */
  background-color: #f1f1f1;
  min-height: 4rem;
  margin-bottom: 0.4rem;

  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);

  /* padding: 8px;
  margin-top: 8px; */
}

.CompanyCard__card-vacancies-details-title {
  color: var(--grey-color-1);
  padding-left: 4rem;
  font-size: var(--normal-font-size);

  /* text-align: center; */
}

.CompanyCard__card-vacancies-details-title:hover {
  color: var(--primary-color);
}

/*################### End - Normal View ###################*/

/*################### Start - Response View ###################*/

@media (max-width: 766px) {
  .CompanyCard__card-details-container {
    min-height: 6rem;
  }

  .CompanyCard__card-vacancies-details-card {
    display: grid;
    grid-template-columns: 1fr;
  }

  .CompanyCard__card-details-space {
    margin-bottom: 1rem;
  }

  .CompanyCard__Vacancy__rating {
    display: none;
  }

  .CompanyCard__card-vacancies-details-title {
    padding-left: 0;
    text-align: center;
  }
}
/*################### End - Responsive View ###################*/
