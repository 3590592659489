/*
supporting links
https://maketintsandshades.com/#28b17b

https://yeun.github.io/open-color/


/*
--- 01 TYPOGRAPHY SYSTEM
- Font Sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Font Weights:
Default: 400
Medium: 500
Semi-Bold: 600
Bold: 700

- Line Heights:
Default: 1
Small: 1.05
Medium: 1.2
Paragraph Default: 1.6

- Letter spacing:
-0.5px
0.75px

--- 02 COLORS
- Primary: #28b17b

- Tints: 
#fdf2e9
#fae5d3

- Shades:
#073137


- Accents:

- Greys:
#888
#767676 (lightest grey allowed on white)
#6f6f6f (lighest grey allowed on #fdf2e9)
#555
#333

--- 05 SHAWDOWS

--- 06 BORDER-RADIUS
Default: 9px
Medium: 11px


--- 07 WHITESPACE
- Spacing System (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
*/

/*=============== GOOGLE FONTS ===============*/
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600,800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

/*=============== VARIABLES CSS ===============*/
:root {
  /* --header-height: 4rem; */

  /*========== Colors ==========*/

  /* Colors */
  --primary-color: #28b17b; /* Main color used for primary elements */
  --primary-shade-1: #104731;
  --primary-shade-2: #1c7c56;
  --primary-tint-1: #53c195;
  --primary-tint-2: #7ed0b0;
  --background-color: #f9f9f9; /* Background color of the page */
  --grey-color-1: #393939; /* Default text color */
  --grey-color-2: #838383; /* secondary text color */
  --success-color: #28b17b; /* Color for success messages */
  --warning-color: #faa800; /* Color for warning messages */
  --error-color: #d30201; /* Color for error messages */
  --grey-color: #212121;
  --white-color: #d4d4d4;

  /* Font Sizes */
  --font-size-sm: 14px; /* Small text size */
  --font-size-md: 16px; /* Medium text size */
  --font-size-lg: 18px; /* Large text size */

  /* Spacing */
  --spacing-sm: 8px; /* Small spacing unit */
  --spacing-md: 16px; /* Medium spacing unit */
  --spacing-lg: 24px; /* Large spacing unit */

  /* --hue: 174;
  --sat: 63%; */
  --hue: 197;
  --sat: 99%;
  /* --first-color: hsl(var(--hue), var(--sat), 40%);
  --first-color-alt: hsl(var(--hue), var(--sat), 36%); */
  /* --title-color: hsl(var(--hue), 12%, 15%); */
  /* --text-color: hsl(var(--hue), 8%, 35%); */
  /* --text-color: #212121; */
  --body-color: hsl(var(--hue), 100%, 99%);
  --container-color: #fff;
  /* --grey-color: #212121;
  --white-color: #d4d4d4; */

  /*========== Font and typography ==========*/
  /* --body-font: 'Open Sans', sans-serif; */
  --body-font: 'Poppins', sans-serif;
  --h1-font-size: 3rem;
  --h2-font-size: 2.5rem;
  /* --normal-font-size: 0.938rem;
  --tiny-font-size: 0.625rem; */
  --normal-font-size: 1.8rem;
  --tiny-font-size: 1.2rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

/* Dark theme */
.dark-theme {
  --primary-color: #17a2b8;
  --secondary-color: #343a40;
  /* other variables for dark theme */
}

/* Light theme */
.light-theme {
  --primary-color: #dc3545;
  --secondary-color: #f8f9fa;
  /* other variables for light theme */
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* html {
  scroll-behavior: smooth;
} */
html {
  font-size: 62.5%; /* 1rem = 10px */
  scroll-behavior: smooth;
}

body {
  margin: 0 0 0 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  font-weight: 400;
  background-color: var(--body-color);
  color: var(--grey-color-1);
  line-height: 1.6;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.no-select {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* 
img {
    max-width: 100%;
    height: auto;
} */

/*################### Start - Normal View ###################*/
.section__title {
  font-size: var(--h1-font-size);
  color: var(--title-color);
  text-align: center;
  margin-bottom: 3rem;
}

.section__height {
  height: 100vh;
}

/*=============== LAYOUT ===============*/
.container {
  /* max-width: 968px; */
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;
  /* margin-left: 2rem;
  margin-right: 2rem; */
}

/*=============== HEADER ===============*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 10rem; */
  /* background-color: var(--container-color); */
  z-index: var(--z-fixed);
  transition: 0.4s;
  /* border: 2px solid black */
  background-color: var(--grey-color);
}
/*################### End - Normal View ###################*/

/*################### Start - responsive View ###################*/

/* For small devices */
@media screen and (max-width: 400px) {
  :root {
    --h1-font-size: 1.6rem;
    --h2-font-size: 1.4rem;
    --normal-font-size: 1.2rem;
    --tiny-font-size: 1rem;
  }
}

@media screen and (min-width: 401px) {
  :root {
    --h1-font-size: 2rem;
    --h2-font-size: 1.8rem;
    --normal-font-size: 1.6rem;
    --tiny-font-size: 1rem;
  }
}
/* For medium devices */
@media screen and (min-width: 576px) {
  :root {
    --h1-font-size: 2rem;
    --h2-font-size: 1.8rem;
    --normal-font-size: 1.6rem;
    --tiny-font-size: 1rem;
  }
}

@media screen and (min-width: 767px) {
  :root {
    --h1-font-size: 2.2rem;
    --h2-font-size: 2rem;
    --normal-font-size: 1.8rem;
    --tiny-font-size: 1rem;
  }
}

/* For large devices */
@media screen and (min-width: 1024px) {
  :root {
    --h1-font-size: 2.4rem;
    --h2-font-size: 2.2rem;
    --normal-font-size: 2rem;
    --tiny-font-size: 1rem;
  }
}
/*################### End - responsive View ###################*/
