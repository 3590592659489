/*################### Start - Normal View ###################*/

/*=============== NAV ===============*/
.nav {
  /* height: var(--header-height); */
  /* height: 6rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid grey;
  background-color: var(--grey-color); */
}

.nav__img {
  width: 32px;
  border-radius: 50%;
}

.nav__logo {
  /* color: var(--title-color); */
  color: var(--primary-color);
  font-weight: 600;
}

.logo__svg {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
}

.logo__pagename {
  text-transform: uppercase;
  font-size: 1.8rem;
}

.backup_logo {
  display: none;
}

.nav__logotitle {
  display: flex;
  align-items: center;
  justify-content: center;

  letter-spacing: 0.1rem;
}

.nav__list,
.nav__link {
  display: flex;
}

.nav__link {
  flex-direction: column;
  align-items: center;
  row-gap: 0.5rem;
  color: #595959;
  /* color: var(--grey-color-2); */
  font-weight: 600;
  /* color: #d4d4d4; */
}

.nav__link:hover {
  color: var(--primary-color);
}

.nav__list {
  justify-content: space-around;
}

.nav__name {
  /* font-size: var(--font-size-sm); */
  font-size: 1rem;
  /* letter-spacing: -0.1rem; */
  /* display: none;*/ /* Minimalist design, hidden labels */
}

.nav__icon {
  /* font-size: 1.5rem; */
  font-size: 2.5rem;
}

/*Active link*/
.active-link {
  position: relative;
  color: var(--primary-color);
  /* font-size: calc(var(--tiny-font-size) + 2px); */
  /* font-weight: 800; */
  font-size: 1.7rem;
  transition: 0.3s;
}

/* Minimalist design, active link */
.active-link::before {
  content: '';
  position: absolute;
  bottom: -0.5rem;
  width: 4px;
  height: 4px;
  background-color: var(--primary-color);
  border-radius: 50%;
}

/* Change background header */
.scroll-header {
  box-shadow: 0 1px 12px var(--primary-color);
}

/*################### End - Normal View ###################*/

/*################### Start - responsive View ###################*/

/* shows bottom menu for all mobile devices */
@media screen and (max-width: 766px) {
  .nav__menu {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: var(--container-color);
    /* box-shadow: 0 -1px 12px hsla(var(--hue), var(--sat), 15%, 0.15); */
    box-shadow: 0 -1px 5px hsla(0, 0%, 50%, 0.9);
    width: 100%;
    height: 8rem;
    padding: 0 1rem;
    display: grid;
    align-content: center;
    border-radius: 4rem 4rem 0 0;
    transition: 0.4s;
  }

  .nav {
    /* display: flex; */
    align-items: center;
    justify-content: center;

    font-size: 1.6rem;
  }

  .logo__svg {
    margin-right: 10px;
  }

  .backup_logo {
    display: inline;
    margin-left: 10px;
    transform: rotate(90deg);
  }
}

/* For small devices */
/* Remove if you choose, the minimalist design */
@media screen and (max-width: 400px) {
  .nav__name {
    /* display: none; */
    font-size: 0.9rem;
  }

  /* .nav__link {
    color: #212121;
  } */

  .nav__icon {
    font-size: 3.3rem;
  }

  .nav {
    height: 5rem;
  }

  .section {
    padding: 6rem 0 2rem;
  }
}

@media screen and (min-width: 401px) {
  .nav {
    height: 5rem;
  }

  .section {
    padding: 6rem 0 2rem;
  }
}
/* For medium devices */
@media screen and (min-width: 576px) {
  .nav__list {
    /* justify-content: center; */
    justify-content: space-around;
    margin: 0 6rem;
    /* column-gap: 5rem; */
  }
  .nav__icon {
    /* font-size: 1.5rem; */
    font-size: 3.5rem;
  }

  .nav {
    height: 5.5rem;
    font-size: 2rem;
  }

  .section {
    padding: 8rem 0 2rem;
  }
}

@media screen and (min-width: 767px) {
  .section {
    padding: 8rem 0 3rem;
  }
  .logo__svg {
    width: 2.5rem;
    height: 2.5rem;
  }

  .nav {
    height: 6rem;
    font-size: 1.8rem;
  }
  .nav__img {
    display: none;
  }
  .nav__icon {
    display: none;
  }
  .nav__name {
    font-size: 1.6rem;
    /* font-size: var(--normal-font-size); */
    /* display: block; */ /* Minimalist design, visible labels */
  }

  .nav__list {
    justify-content: center;
    margin: 0 2rem;
  }

  .nav__item {
    padding: 0 1.5rem;
  }

  .nav__item:nth-child(3) {
    /* Reorder the third item (HOME) to appear first */
    order: -1; /* Negative value pushes it to the beginning */
  }

  .nav__link {
    /* color: #d4d4d4; */
    color: var(--white-color);
  }

  .active-link {
    position: relative;
    color: var(--primary-color);
    /* font-size: calc(var(--tiny-font-size) + 2px); */
    /* font-weight: 800; */
    font-size: 1.7rem;
    transition: 0.3s;
  }

  /* .nav__link:hover {
    color: var(--first-color);
  } */

  /* First design, remove if you choose the minimalist design */
  /* .active-link::before {
        content: '';
        position: absolute;
        bottom: -0.75rem;
        width: 4px;
        height: 4px;
        background-color: var(--first-color);
        border-radius: 50%;
    } */

  /* Minimalist design */
  .active-link::before {
    /* bottom: -0.75rem; */
    display: none;
  }
}

/* For large devices */
@media screen and (min-width: 1024px) {
  .container {
    margin-left: auto;
    margin-right: auto;
    /* margin-left: 4rem;
    margin-right: 4rem; */

    max-width: 80vw;
  }
  .logo__svg {
    width: 3rem;
    height: 3rem;
  }

  .nav__item:nth-child(3) {
    /* Reorder the third item (HOME) to appear first */
    order: -1; /* Negative value pushes it to the beginning */
  }

  .nav__link {
    color: #d4d4d4;
  }

  .active-link {
    position: relative;
    color: var(--primary-color);
    /* font-size: calc(var(--tiny-font-size) + 2px); */
    /* font-weight: 800; */
    font-size: 1.7rem;
    transition: 0.3s;
  }

  .nav__link:hover {
    color: var(--primary-color);
  }

  .active-link::before {
    display: none;
  }

  .nav {
    /*height: calc(var(--header-height) + 1.5rem); // 4.5rem */
    height: 7rem;
    font-size: 2rem;
  }

  .nav__name {
    font-size: 2rem;
  }

  .section {
    padding: 10rem 0 3rem;
  }
}
/*################### End - responsive View ###################*/
