.vacancies__page-title {
  font-size: var(--h1-font-size);
}

.vacancies__grid-container {
  /* display: grid;
  grid-template-columns: 1fr; */
  width: 90%;
  margin: 2rem auto;
  top: 2rem;
  /* min-height: 80rem; */
  /* grid-gap: 2rem; */
  /* grid-auto-columns: max-content; */
  /* font-size: var(--normal-font-size); */
}

.vacancies__gray-out-effect {
  display: none;
}

@media (max-width: 767px) {
  .vacancies__page-title {
    display: none;
  }
}

@media (max-width: 400px) {
  .vacancies__grid-container {
    width: 95%;
  }
}
