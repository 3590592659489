.VacancyDetails__modal {
  /* position: fixed;
  top: 30px;
  left: 0;
  width: 100%;
  height: 100%; */
  /* background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center; */

  position: fixed;
  width: 100%;
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  overflow-y: auto;
}

/* Styles for the modal content */
.VacancyDetails__modal-content {
  background-color: #f1f1f1;
  max-width: 90%;
  max-height: 90%;
  margin: auto auto;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  /* overflow-y: auto; */
}

/* Styles for the close button */
.VacancyDetails__linkedin-link {
  background-color: var(--primary-color);
  color: white;
  border: none;
  font-weight: 600;

  min-height: 2.4rem;
  height: min-content;
  padding: 1.2rem 2.4rem;

  border-radius: 2.4rem;
  cursor: pointer;
  /* margin-top: 10px; */
  /* font-size: var(--tiny-font-size); */

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  justify-self: center;
  align-self: center;
}

.VacancyDetails__linkedin-link::before {
  content: 'Apply on LinkedIn';
}

/* Close button style on hover */
.VacancyDetails__linkedin-link:hover {
  background-color: var(--primary-shade-1);
}

.VacancyDetails__card-container {
  display: grid;
  grid-template-columns: 3fr 5fr 2fr;
  gap: 1rem;
  /* min-width: 38rem; */

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
  background-color: #f1f1f1;

  width: 100%;
  margin-bottom: 6rem;

  overflow: auto;
}

.VacancyDetails__card-item {
  /* border: 1px solid violet; */
  padding: 10px;

  align-self: center;
}

.VacancyDetails__card-item strong {
  font-weight: 600;
}

.VacancyDetails__title {
  /* color: #17a2b8; */
  font-size: var(--h1-font-size);
  font-weight: 600;
  text-align: center;

  /* padding: 2rem 2rem; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.VacancyDetails__company {
  justify-self: center;
  /* align-self: center; */
}

.VacancyDetails__details {
  grid-column: span 3;
  font-size: var(--normal-font-size);
}

.VacancyDetails__posted {
  grid-column: span 3;
  font-size: var(--tiny-font-size);
  margin-bottom: 2rem;
}

.VacancyDetails__description {
  grid-column: span 3;
  line-height: 1.8;
}

.VacancyDetails__description p {
  margin-bottom: 2.5rem;
}

.VacancyDetails__linkedin-arrow {
  font-size: 4rem;
  padding-left: 0.5rem;
}

@media screen and (max-width: 760px) {
  .VacancyDetails__modal-content {
    max-width: 95%;
    padding: 1rem;
  }
  .VacancyDetails__linkedin-link {
    font-size: var(--tiny-font-size);
    padding: 1rem 1.6rem;
    border-radius: 2rem;
  }
  .VacancyDetails__linkedin-link::before {
    content: 'Apply';
  }

  .VacancyDetails__description {
    line-height: 2;
  }

  .VacancyDetails__linkedin-arrow {
    font-size: 2rem;
  }
}
