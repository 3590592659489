.KPICard-container {
  position: relative;
  margin: 2rem auto;
  background-color: #f1f1f1;

  border-radius: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 90%;
  height: 25vh;
}

.KPICard-container::after {
  position: absolute;
  content: 'IN';
  font-size: 1rem;
  font-weight: 600;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  /* border: 1px solid var(--grey-color-2); */

  color: var(--grey-color-2);
  /* background-color: var(--primary-color); */

  display: flex;
  justify-content: center;
  align-items: center;
}

.KPICard-item {
  flex: 1;
  /* flex: 1;

  margin: auto auto;
  text-align: center; */

  display: flex;
  flex-direction: column;
  /* justify-content: flex-end; */
  align-items: center;
  /* margin: auto auto; */
  /* text-align: center; */
}
.KPICard-number-icon {
  font-size: 2rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;

  color: #f1f1f1;
  background-color: var(--primary-color);

  display: flex;
  justify-content: center;
  align-items: center;
}

.KPICard-number-value {
  /* color: var(--primary-shade-1); */
  font-size: 5rem;
  font-weight: 600;
  /* margin-top: auto; Push to the bottom */
}

.KPICard-number-description {
  font-size: var(--tiny-font-size);
  color: var(--grey-color-2);
  text-transform: uppercase;
  font-weight: 600;
}
