/*################### Start - Normal View ###################*/
.VacancyCard__card-details-container {
  display: grid;
  grid-template-columns: 2fr 7fr 1fr;
  gap: 1rem;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
  background-color: #f1f1f1;

  width: 100%;
  margin-bottom: 2rem;

  /* position: relative; */
  /* z-index: -1; */

  transition: all 0.4s;
  cursor: default;
}

/* .card-details-main {
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 1rem;

  width: 100%;
} */

.VacancyCard__card {
  /* border: 1px solid #000; */
  padding: 1.2rem;
}

.VacancyCard__card-details-company {
  /* grid-row-start: 1;
  grid-row-end: span 2; */
  grid-row: span 2;
  font-size: var(--h2-font-size);
  font-weight: 600;

  place-self: center;
  text-align: center;

  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* width: 100%;
  height: 100%; */
}

.VacancyCard__card-details-title {
  font-size: var(--h2-font-size);
}

.VacancyCard__card-details-fav {
  /* place-self: center; */
  /* cursor: pointer; */

  display: flex;
  align-items: center;
  justify-content: center;

  grid-row: span 2;
}

.VacancyCard__card-details-posted {
  font-size: var(--tiny-font-size);
  cursor: default;
}

.VacancyCard__card-link:link,
.VacancyCard__card-link:visited {
  color: var(--grey-color-1);

  cursor: pointer;
}

.VacancyCard__card-link:hover {
  color: var(--primary-color);
}

.VacancyCard__card-details-container:hover {
  transform: translateY(-0.3rem);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.15);
}
.VacancyCard__card-details-container:active {
  transform: translateY(-0.1rem);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
/*################### End - Normal View ###################*/

/*################### Start - Response View ###################*/

@media (max-width: 766px) {
  .VacancyCard__card-details-container {
    display: grid;
    grid-template-columns: 2fr 6fr 2fr;
    gap: 1rem;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
    background-color: #f1f1f1;
  }

  .VacancyCard__card-details-main {
    display: grid;
    grid-template-columns: 1fr 2fr;
    /* gap: 1rem; */

    width: 100%;
  }

  .VacancyCard__card-details-title {
    max-height: 8rem;
    font-size: var(--normal-font-size);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

@media (max-width: 400px) {
  .VacancyCard__card-details-container {
    display: grid;
    grid-template-columns: 2fr 8fr;
    gap: 0rem;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
    background-color: #f1f1f1;
    margin-bottom: 1.2rem;
  }

  .VacancyCard__card-details-fav {
    display: none;
  }
}
/*################### End - Responsive View ###################*/
