.favorites__page-title {
  font-size: var(--h1-font-size);
}

.favorites__grid-container {
  width: 90%;
  margin: 2rem auto;
  top: 2rem;
}

@media (max-width: 767px) {
  .favorites__page-title {
    display: none;
  }
}

@media (max-width: 400px) {
  .favorites__grid-container {
    width: 95%;
  }
}
