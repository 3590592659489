.AboutPage__container {
  font-size: 1.6rem;
  line-height: 1.8;
}

.AboutPage__container p {
  margin-bottom: 2.4rem;
}

.AboutPage__title {
  font-size: 2.4rem;

  margin: 2rem 0;
}

.AboutPage__bold {
  font-size: 1.8rem;
  font-style: normal;
}

.AboutPage__signature {
  margin-top: 4rem;
  font-style: italic;
}
