.VacanyDetailsPage__back-button {
  color: var(--primary-color);
  font-size: 5rem;
  cursor: pointer;
  transition: 0.5s;
}

.VacanyDetailsPage__fav-button-container {
  position: relative;
  display: inline-block;
}

.VacanyDetailsPage__fav-button {
  color: var(--primary-color);
  font-size: 3.6rem;
  cursor: pointer;
  background: transparent;
  border: none;
  transition: 0.5s;
}

.VacanyDetailsPage__back-button:hover,
.VacanyDetailsPage__fav-button:hover {
  color: var(--primary-shade-1);
}

.VacanyDetailsPage__details-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.VacanyDetailsPage__tooltip {
  position: absolute;
  font-size: var(--tiny-font-size);
  bottom: 95%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.6rem;
  /* background-color: #333; */
  background-color: var(--grey-color-1);
  color: #fff;
  border-radius: 0.6rem;

  opacity: 0.7;
  pointer-events: none;
  white-space: nowrap; /* Prevent the tooltip from wrapping to multiple lines */
}

@media (max-width: 767px) {
  .VacanyDetailsPage__tooltip {
    position: absolute;
    bottom: 70%;
    left: 0%;
  }
}
