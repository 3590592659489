/* Loading Container */
.LoadingDiv__container {
  margin: 2rem auto;
  background-color: #f1f1f1;

  border-radius: 2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 90%;
  height: 25vh;
}

.LoadingDiv__loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid var(--primary-shade-2); /* Replace with the desired color for the spinner */
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  animation: spin 1s linear infinite; /* Add a spinning animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
