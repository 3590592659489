.Homepage__container {
  position: relative;
  width: 95%;
  margin: 2rem auto;
}

.Homepage__background {
  position: absolute;
  left: 0;
  top: 0;
  /* background-color: var(--primary-color); */
  background: linear-gradient(
    to right bottom,
    var(--primary-shade-2),
    var(--primary-tint-1)
  );

  width: 100%;
  height: 25vh;
}

.KPICard-info-container {
  margin-top: 4rem;
  /* transition: all 4s; */
}

.KPICard-info-loading {
  display: none;
}

.KPICard-info-title {
  font-size: var(--h1-font-size);
  font-weight: 600;
  margin-bottom: 2rem;
  padding-left: 4rem;
}
