.CompaniesPage__page-title {
  font-size: var(--h1-font-size);
}

.CompaniesPage__grid-container {
  width: 80%;
  margin: 2rem auto;
}

@media (max-width: 767px) {
  .CompaniesPage__grid-container {
    width: 90%;
    margin: 2rem auto;
  }

  .CompaniesPage__page-title {
    display: none;
  }
}

@media (max-width: 320px) {
  .CompaniesPage__grid-container {
    width: 95%;
  }
}
